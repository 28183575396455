<template>
  <div id="apply-steps" class="flex flex-wrap sm: justify-center gap-14 sm:pt-8 sm:pb-8">
    <div id="text-box" class="w-3/4 relative -top-5 sm:top-0 z-10 bg-grey sm:w-full">
      <p id="apply-text" class="font-bold px-1.5 sm:text-3xl">{{ $t("apply.applyText") }}</p>
    </div>
    <div
      class="step-box relative w-60 sm:w-1/5 h-24 sm:h-32 ml-6"
      v-for="(step, index) in $t('apply.steps')"
      :key="index"
    >
      <div class="absolute -left-6 top-6 sm:top-1/4 sm:-left-10">
        <div class="red-circle flex justify-center items-center">
          <p class="text-white font-montserrat font-bold text-lg sm:text-3xl">{{ step.number }}</p>
        </div>
      </div>
      <p class="step-title mb-2 font-semibold text-xl sm:mt-0 xxl:text-2xl sm:text-2xl">{{ step.title }}</p>
      <p class="step-description text-xs xxl:text-xl sm:mt-4 sm:text-base">{{ step.description }}</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
#apply-steps {
  padding-bottom: 70px;
  @media screen and (min-width: 480px) {
    padding-bottom: 100px;
  }
}

#text-box {
  margin: auto;
  padding-top: 25px;
  padding-bottom: 25px;
  border-radius: 5px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  @media screen and (min-width:480px) {
    box-shadow: none;
  }
}

#apply-text {
  color: $dark_red;
  font-family: "Montserrat";
  text-align: center;
}

.step-box {
  background-color: $black;
  border-radius: 5px;
  padding: 20px 30px;
  @media screen and (min-width:480px){
    padding-left: 50px;
    padding-bottom: 120px;
  }
}

.red-circle {
  background-color: $dark_red;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: -6px;
    box-sizing: border-box;
    width: 30px;
    height: 60px;
    border: 1px solid $dark_red;
    border-right: 0;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  @media screen and (min-width:480px){
    height: 80px;
    width: 80px;
    &::after {
      left: -10px;
      width: 50px;
      height: 100px;
    }
  }
}

.step-title {
  color: $white;
  font-family: "Montserrat";
  position: relative;
  line-height: 1.2em;
}

.step-description {
  color: $white;
  font-family: "Roboto";
  position: relative;
  line-height: 1.2em;
}
</style>
