<template>
  <div id="apply-page">
    <ApplyHero/>
    <ApplySteps/>
    <Newsletter/>
  </div>
</template>

<script>
import ApplyHero from '../components/apply/ApplyHero.vue';
import ApplySteps from '../components/apply/ApplySteps.vue';
import Newsletter from '../components/contact/Newsletter.vue';

export default {
  components: {
    ApplySteps,
    Newsletter,
    ApplyHero,
  },
  data() {
    return {
      //comentariu ca sa nu dea ESLint warning
    };
  },
};
</script>

<style lang="scss" scoped>
//comentariu ca sa nu dea ESLint warning
</style>
