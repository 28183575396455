<template>
  <div id="countdown" class="flex gap-x-7">
    <div class="flex flex-col items-center sm:relative">
      <div class="digit w-10 h-10 flex items-center justify-center rounded-md
                  font-montserrat font-bold sm:text-4-5xl sm:w-24 sm:h-24 sm:relative sm:z-20"
                :class="{'bg-white text-dark_red': startRecruiting, 'bg-light_grey text-black': !startRecruiting}">
        <p> {{days}} </p>
      </div>
      <div class="unit text-white font-montserrat text-xs py-2 sm:text-lg sm:font-bold">
        {{$t("apply.hero.countdown.days")}}
      </div>
      <div class="hidden sm:block absolute w-28 h-4 -left-2 top-10 sm:z-10 bg-black rounded-md"/>
      <div class="hidden sm:block absolute w-24 h-0.25 left-0 top-12 sm:z-30 bg-black opacity-50"/>
    </div>
    <div class="flex flex-col items-center sm:relative">
      <div class="digit w-10 h-10 flex items-center justify-center rounded-md
                  font-montserrat font-bold sm:text-4-5xl sm:w-24 sm:h-24 sm:relative sm:z-20"
                :class="{'bg-white text-dark_red': startRecruiting, 'bg-light_grey text-black': !startRecruiting}">
        <p> {{hours}} </p>
      </div>
      <div class="unit text-white font-montserrat text-xs py-2 sm:text-lg sm:font-bold">
        {{$t("apply.hero.countdown.hours")}}
      </div>
      <div class="hidden sm:block absolute w-28 h-4 -left-2 top-10 sm:z-10 bg-black rounded-md"/>
      <div class="hidden sm:block absolute w-24 h-0.25 left-0 top-12 sm:z-30 bg-black opacity-50"/>
    </div>
    <div class="flex flex-col items-center sm:relative">
      <div class="digit w-10 h-10 flex items-center justify-center rounded-md
                  font-montserrat font-bold sm:text-4-5xl sm:w-24 sm:h-24 sm:relative sm:z-20"
            :class="{'bg-white text-dark_red': startRecruiting, 'bg-light_grey text-black': !startRecruiting}">
        <p> {{minutes}} </p>
      </div>
      <div class="unit text-white font-montserrat text-xs py-2 sm:text-lg sm:font-bold">
        {{$t("apply.hero.countdown.minutes")}}
      </div>
      <div class="hidden sm:block absolute w-28 h-4 -left-2 top-10 sm:z-10 bg-black rounded-md"/>
      <div class="hidden sm:block absolute w-24 h-0.25 left-0 top-12 sm:z-30 bg-black opacity-50"/>
    </div>
  </div>
</template>

<script>
export default {
  props: ["deadline", "startRecruiting"],
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      date: Math.trunc(Date.parse(this.deadline) / 1000),
    };
  },
  computed: {
    seconds() {
      return (this.date - this.now) % 60 > 0 ? (this.date - this.now) % 60 : 0;
    },
    minutes() {
      return Math.trunc((this.date - this.now) / 60) % 60 > 0
        ? Math.trunc((this.date - this.now) / 60) % 60
        : 0;
    },
    hours() {
      return Math.trunc((this.date - this.now) / 60 / 60) % 24 > 0
        ? Math.trunc((this.date - this.now) / 60 / 60) % 24
        : 0;
    },
    days() {
      return Math.trunc((this.date - this.now) / 60 / 60 / 24) > 0
        ? Math.trunc((this.date - this.now) / 60 / 60 / 24)
        : 0;
    },
  },
  mounted() {
    window.setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
// .digit {
//   position: relative;
//   z-index: 20!important;
//   &::before {
//     content: '';
//     position: absolute;
//     z-index: 10;
//     top: 42%;
//     left: -7.5%;
//     width: 115%;
//     height: 16px;
//     border-radius: 4px;
//     background-color: $black;
//   }
// }
</style>
